.Footer {
    background-color: black;
    height: 15vh;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-company-name {
    color: white;
}

.company-email {
    color: white;
}

@media only screen and (max-width: 600px) {
    .footer-company-name {
        font-size: 5.5vw;
    }

    .company-email {
        color: white;
        font-size: 3vw;
    }
}

