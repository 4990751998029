$darkGreen: #344E41;
$mediumGreen: #3A5A40;
$lightGreen: #588157;
$white: #FBFAF5;
$tanGreen: #A3B18A;
$grey: #444444;
$lightGrey: #ECECEC;
$tan: #DAD7CD;
$darkRed: #B30000;
$lightRed: #d00202;
$black: #000000;
$blueGrey: #F4F7FA
