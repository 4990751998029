@use "../../../abstract/variables" as *;


.Header {
    height: 15vh;
    background-color: $black;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sungate-name {
    color:white;
    margin: 0;
}

.sungate-logo {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.logo-container {
    display: flex;
    align-items: center;
}

.hamburger-button {
    width: 20px;
}

.hamburger-button:hover {
    width: 20px;
    cursor: pointer;
}

.Header-hamburger {
    height: 15vh;
    background-color: $black;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.aboutus-link-text {
    color: aliceblue;
    font-weight: bolder;
    margin-left: 10px;
    text-decoration: none; 
    animation: fadeIn 5s; // Add margin for spacing between the two items
}

.x-button {
    width: 20px;
}

.x-button:hover {
    cursor: pointer;
}

.hamburger-subcontainer {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.nav-link {
    margin-top: 10%;
    color: aliceblue;
    text-decoration: none;
    font-weight: bold;
    box-shadow: inset 0 0 0 0 #54b3d6;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.nav-link:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;
    text-decoration-line: underline;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .sungate-name {
        font-size: 6vw;
    }

    .sungate-logo {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
}

