@use "../../../abstract/variables" as *;

.aboutus-title-container {
    display: flex;
    justify-content: center;
    padding-top: 5vh;
}

.aboutus-subsection-1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2vh
}

.aboutus-image-1 {
    width: 30%;
}

.aboutus-content-container-1 {
    font-size: 1vw;
    width: 35%;
}

.aboutus-subsection-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    font-size: 1vw;
}

.contact-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden; 
    padding-bottom: 5vh;// Ensure that the overlay text doesn't overflow the container

    .contact-us-image {
        width: 75%; // Ensure the image takes up the full width of the container
    }

    .overlay-text {
        position: absolute;
        top: 40%;
        font-weight: bolder;
        transform: translateY(-50%);
        color: white;
        font-size: 2.3vw;
        text-align: center;
        z-index: 1; // Ensure the text appears above the image
        text-decoration: underline;
    }
}


.contact-button {
    background-color: black; /* Green background color */
        color: white; /* White text color */
        padding: 10px 20px; /* Padding for better appearance */
        border: none; /* Remove border */
        border-radius: 5px; /* Add a slight border-radius for rounded corners */
        cursor: pointer; /* Add pointer cursor on hover */
        font-size: 1.2rem; /* Adjust font size */
        transition: background-color 0.3s;
        font-weight: bold; /* Add a smooth transition effect on hover */

        &:hover {
            background-color: #54b3d6; /* Darker green color on hover */
        }
}

@media only screen and (max-width:600px){
    .aboutus-subsection-1{
        flex-direction: column;
    }

    .aboutus-image-1 {
        width: 95%;
    }

    .aboutus-header-1,
    .aboutus-header-2 {
        font-size: 7.5vw;
    }

    .aboutus-content-container-1 {
        width: 95%;
    }

    .aboutus-paragraph-1,
    .aboutus-paragraph-2{
        font-size: 4.5vw;
    }

    .aboutus-subsection-2{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
    }

    .contact-image-container .contact-us-image {
        width: 90%;
    }

    .contact-us-1 {
        font-size: 4.5vw;
    }
}

