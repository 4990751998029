@use "../../../abstract/variables" as *;

.Home {
    display: flex;
    flex-direction: column;
}

.image-container-1 {
    position: relative;
}

.image-overlay {
    position: relative;
    display: inline-block;
}

.image-text {
    position: absolute;
    top: 27%;
    left: 5%;
    transform: translate(0, -50%);
    color: white;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
}

.image-text-header {
    margin: 0;
}


.row-of-crops {
    object-fit: contain;
    width: 100%;
}

.content-section-1 {
    height: 60vh;
    background-color: $blueGrey;
    padding-top: 4%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
}

.section-title-1 {
    font-size: 2.5rem;
}

.content-container-1 {
    display: flex;
    justify-content: space-between;
}

.content-1,
.content-2,
.content-3,
.content-4 {
    width: 23%;
}

.content-1 {
    font-size: 1.1vw;
}

.content-4 {
    object-fit: cover;
}

.content-section-2 {
    height: 25vh;
    padding-left: 1%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.section-title-2 {
    margin: 0;
    font-size: 2vw;
}

.title-container,
.paragraph-container {
    width: 48%;
}

.content-5 {
    font-size: 1.1vw;
}

.content-section-3 {
    background-color: $blueGrey;
    display: flex;
    height: 300px;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 10vh;
    justify-content: space-between;

}

.subsection-1,
.subsection-2,
.subsection-3 {
    width: 31%;
}

.subsection-title {
    font-size: 1.8vw;
}

.content-section-4 {
    height: 55vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.subsection-4 {
    width: 47%;
    display: flex;
    justify-content: space-around;
}

.subsection-5 {
    width: 44%;
}

.video-container-2,
.image-container-2,
.video-container-3 {
    width: 15vw;
    height: 40vh;
    overflow: hidden;
}


.field-and-controller {
    object-fit: contain;
    width: 100%;
}

.video-player {
    width: 100%;
    height: 100%;
}

.field-controller {
    object-fit: cover;
    /* Maintain aspect ratio and cover the container */
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .row-of-crops {
        width: 100%;
        height: 86vh;
        object-fit: cover;
    }

    .image-text {
        position: absolute;
        top: 22%;
        left: 0%;
        transform: translate(0, -50%);
        color: white;
        padding: 10px;
        border-radius: 0%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .content-section-1 {
        height: 200vh;
    }

    .content-container-1 {
        flex-direction: column;
        align-items: center;
    }

    .content-2,
    .content-3,
    .content-4 {
        width: 90%;
    }

    .content-1 {
        font-size: 4.5vw;
        width: 95%;
    }

    .content-2,
    .content-3,
    .content-4 {
        margin-bottom: 5vh;
    }

    .content-section-2 {
        display: flex;
        flex-direction: column;
        height: 50vh;
        justify-content: space-evenly;
    }

    .title-container,
    .paragraph-container {
        width: 80%;
    }

    .section-title-2 {
        margin: 0;
        font-size: 7vw;
    }

    .content-5 {
        font-size: 4.1vw;
    }

    .video-player {
        width: 100%;
        height: 100%;
    }

    .content-section-3 {
        background-color: #F4F7FA;
        display: flex;
        height: 95vh;
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 5vh;
        padding-bottom: 5vh;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .subsection-1,
    .subsection-2,
    .subsection-3 {
        width: 90%;
    }

    .subsection-title {
        font-size: 7.5vw;
    }

    .content-section-4 {
        height: 200vh;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .subsection-4 {
        height: 160vh;
        width: 95vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subsection-5 {
        width: 85%;
    }

    .video-container-2,
    .image-container-2,
    .video-container-3 {
        width: 60vw;
        height: 50vh;
        overflow: hidden;
    }

}